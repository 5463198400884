import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {VehicleInsurance} from "../modules/apps/InsurancePortal/components/VehicleInsurance"
import {BranchList} from "../modules/apps/branchNew/component/BranchList"


const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const CustomerPage = lazy(() => import('../modules/apps/customer/CustomerPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const ProfilesPage = lazy(() => import('../modules/apps/profiles/ProfilesPage'))
  const DriverPage = lazy(() => import('../modules/apps/driver/DriverPage'))
  const FleetsPage = lazy(() => import('../modules/apps/fleets/FleetsPage'))
  const PurchasePage = lazy(() => import('../modules/apps/purchase/PurchasePage'))
  const BreakdownPage = lazy(() => import('../modules/apps/breakdown/BreakdownPage'))
  const MaintenancePage = lazy(() => import('../modules/apps/maintenance/MaintenancePage'))
  const AccidentPage = lazy(() => import('../modules/apps/accident/AccidentPage'))
  const InsurancePage = lazy(() => import('../modules/apps/insurance/InsurancePage'))
  const VechicleInsurance = lazy(() => import('../modules/apps/InsurancePortal/VechicleInsurance'))
  const CargoInsurance = lazy(() => import('../modules/apps/InsurancePortal/VechicleInsurance'))
  const ClaimPage = lazy(() => import('../modules/apps/claim/ClaimPage'))
  const ReminderPage = lazy(() => import('../modules/apps/reminder/ReminderPage'))
  const PaymentPage = lazy(() => import('../modules/apps/payment/PaymentPage'))
  const ReportsPage = lazy(() => import('../modules/apps/reports/ReportsPage'))
  const EcreditPage = lazy(() => import('../modules/apps/ecard/EcreditPage'))
  const PerformancePage = lazy(() => import('../modules/apps/performance/PerformancePage'))
  const BranchPage = lazy(() => import('../modules/apps/branchNew/BranchPage'))
 

  return (
    <Routes >
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/customer/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/profiles/*'
          element={
            <SuspensedView>
              <ProfilesPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/driver/*'
          element={
            <SuspensedView>
              <DriverPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/fleets/*'
          element={
            <SuspensedView>
              <FleetsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/purchase/*'
          element={
            <SuspensedView>
              <PurchasePage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/breakdown/*'
          element={
            <SuspensedView>
              <BreakdownPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/maintenance/*'
          element={
            <SuspensedView>
              <MaintenancePage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/accident/*'
          element={
            <SuspensedView>
              <AccidentPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/insurance/*'
          element={
            <SuspensedView>
              <InsurancePage />
              <VehicleInsurance />
              <CargoInsurance />
            </SuspensedView>
          }
        />

        <Route
          path='apps/claim/*'
          element={
            <SuspensedView>
              <ClaimPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/reminder/*'
          element={
            <SuspensedView>
              <ReminderPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/payment/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/reports/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ecard/*'
          element={
            <SuspensedView>
              <EcreditPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/performance/*'
          element={
            <SuspensedView>
              <PerformancePage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/branch/*'
          element={
            <SuspensedView>
              <BranchPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
