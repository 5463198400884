import React, {FC} from 'react'


const VehicleInsurance: FC = () => {
  return (

   <div className='w-full lg:w-12/12'>



        <form>
                <div className='card-body p-8'>
                  <div className='row mb-9'>
                    <div className='col-lg-12'>
                      <div className='row'>
                        <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Light Truck</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <label className=' form-label '>Light Van</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Light Bus</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Permit A- Straight Truck</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Permit C- Straight Truck</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                         <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Permit A- Semi Trailer</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                         <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>-	Permit A- B-Double Trailer</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Permit A- B-Double Trailer</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Permit C- B-Double Trailer</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Permit KA- Prime Mover</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                         <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Permit KA- Semi Trailer</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                         <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Bas Persiaran</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Bas Express</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                        <div className='col-lg-6 fv-row'>
                          <label className=' form-label'>Etc</label>
                          <input
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          />
                        </div>

                      </div>
                    </div>
                  </div>


                </div>
              </form>

      </div>


  )
}

export { VehicleInsurance }