/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
     

      <SidebarMenuItemWithSub
        to='/apps/driver'
        title='Driver'
        fontIcon='bi-chat-left'
        icon='bi bi-person-add'
      >
        <SidebarMenuItem to='/apps/driver/driver-list' title='Driver List' hasBullet={true} />
       
       
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/fleets'
        title='Fleets'
        fontIcon='bi-chat-left'
        icon='bi bi-truck'
      >
        <SidebarMenuItem to='/apps/fleets/fleets-list' title='Fleets List' hasBullet={true} />
        <SidebarMenuItem to='/apps/fleets/assign-trips' title='Assign Trips' hasBullet={true} />
       
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/purchase'
        title='Purchase Tracking'
        icon='bi bi-handbag'
      >
      
       
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/breakdown'
        title='Breakdown'
        fontIcon='bi-chat-left'
        icon='bi bi-tools'
      >
        <SidebarMenuItem to='/apps/Breakdown/breakdown-request' title='Breakdown Request' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/maintenance'
        title='Maintenance & Service'
        fontIcon='bi-chat-left'
        icon='bi bi-clipboard-data'
      >
        <SidebarMenuItem to='/apps/Maintenance/maintenance-service' title='Maintenance Service Request' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/accident'
        title='Accident'
        fontIcon='bi-chat-left'
        icon='bi bi-ev-front'
      >
        <SidebarMenuItem to='/apps/accident/accident-request' title='Accident Request' hasBullet={true} />
        
      </SidebarMenuItemWithSub>


      <SidebarMenuItemWithSub
        to='/apps/insurance Portal'
        title='Insurance Portal'
        fontIcon='bi-chat-left'
        icon='bi bi-card-checklist'
      >
        <SidebarMenuItem to='/apps/insurance/vechicle-insurance' title='Vehicle Insurance' hasBullet={true} />
        <SidebarMenuItem to='/apps/insurance/cargo-insurance' title='Employee Benefits Insurance' hasBullet={true} />
        <SidebarMenuItem to='/apps/insurance/cargo-insurance' title='Cargo Insurance' hasBullet={true} />
        <SidebarMenuItem to='/apps/insurance/renewel-purchase' title='Property Insurance' hasBullet={true} />
        <SidebarMenuItem to='/apps/insurance/renewel-purchase' title='Travel Insurance' hasBullet={true} />
        <SidebarMenuItem to='/apps/insurance/renewel-purchase' title='Casualty Insurance' hasBullet={true} />

      </SidebarMenuItemWithSub>
  <SidebarMenuItemWithSub
        to='/apps/insurance'
        title='Insurance'
        fontIcon='bi-chat-left'
        icon='bi bi-card-checklist'
      >
        <SidebarMenuItem to='/apps/insurance/renewel-purchase' title='Renewel & Purchase' hasBullet={true} />

      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/claim'
        title='Claim & Refund'
        fontIcon='bi-chat-left'
        icon='bi bi-wallet2'
      >
        <SidebarMenuItem to='/apps/claim/claim-status' title='Claim Status' hasBullet={true} />
        <SidebarMenuItem to='/apps/claim/insurance-claim' title='Insurance Claim' hasBullet={true} />
        <SidebarMenuItem to='/apps/claim/other-claim' title='Other Claim' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/reminder'
        title='Reminder'
        fontIcon='bi-chat-left'
        icon='bi bi-alarm'
      >
        <SidebarMenuItem to='/apps/reminder/expiry' title='Expiry' hasBullet={true} />
        <SidebarMenuItem to='/apps/reminder/warranty' title='Warranty' hasBullet={true} />
        <SidebarMenuItem to='/apps/reminder/in-action' title='In Action' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/payment'
        title='Payment'
        fontIcon='bi-chat-left'
        icon='bi bi-credit-card'
      >
        <SidebarMenuItem to='/apps/payment/payment-invoice' title='Payment & Invoice' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/reports'
        title='Reports'
        fontIcon='bi-chat-left'
        icon='bi bi-flag'
      >
        <SidebarMenuItem to='/apps/reports/trip-summary' title='Trip Summary' hasBullet={true} />
        <SidebarMenuItem to='/apps/reports/fuel-summary' title='Fuel Summary' hasBullet={true} />
        <SidebarMenuItem to='/apps/reports/tyre-management' title='Tyre Management' hasBullet={true} />
        <SidebarMenuItem to='/apps/reports/fire-extinguisher' title='Fire Extinguisher' hasBullet={true} />
        <SidebarMenuItem to='/apps/reports/fleet-health ' title='Fleet Health Analytics' hasBullet={true} />
        <SidebarMenuItem to='/apps/reports/maintenance-log' title='Maintenance Log' hasBullet={true} />
      </SidebarMenuItemWithSub>


      <SidebarMenuItemWithSub
        to='/apps/ecard'
        title='E-Credit Card'
        fontIcon='bi-chat-left'
        icon='bi bi-credit-card-2-front'
      >
        <SidebarMenuItem to='/apps/ecard/ecredit-card' title='E-Credit Card Centre' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/performance'
        title='Performance'
        fontIcon='bi-chat-left'
        icon='bi bi-clipboard2-check'
      >
        <SidebarMenuItem to='/apps/performance/performance-suggestion' title='Performance & Suggestion' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/performance'
        title='Performance'
        fontIcon='bi-chat-left'
        icon='bi bi-clipboard2-check'
      >
        <SidebarMenuItem to='/apps/performance/performance-suggestion' title='Performance & Suggestion' hasBullet={true} />
        
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/branch'
        title='Branch'
        fontIcon='bi-chat-left'
        icon='bi bi-clipboard2-check'
      >
        <SidebarMenuItem to='/apps/branch/registration' title='Registration' hasBullet={true} />
        <SidebarMenuItem to='/apps/branch/principleowners' title='Principle Owners' hasBullet={true} />
        <SidebarMenuItem to='/apps/branch/personincharge-details' title='Person In-Charge Details' hasBullet={true} />
        <SidebarMenuItem to='/apps/branch/banking-details' title='Banking Details' hasBullet={true} />

      </SidebarMenuItemWithSub>

      




      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className=''>
            <KTIcon iconName='' className='' />
          </span>
          <span className='menu-title'> </span>
        </a>
      </div>

    </>

    
  )
}

export {SidebarMenuMain}
